<template>
    <a-config-provider :locale="locale" :theme="mallTheme">
        <router-view></router-view>
    </a-config-provider>
</template>
<script setup>
import { theme } from "ant-design-vue";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
dayjs.locale("zh-cn");
const locale = ref(zhCN);
const mallTheme = reactive({
    algorithm: theme.compactAlgorithm,
    token: {
        colorPrimary: "#e0383e"
    }
});
</script>
<style></style>
