import { createRouter, createWebHistory } from "vue-router";
import routers from "./modules/index";
import { start, close } from '@/utils/nprogress';
import { getLoginUrl, getToken } from "@/utils/login";
import { managerAuthCheck } from "@/api/manage";
import { setTabActive } from "@/components/common/RouteTabs";
import store from '../store';

const routes = [
    {
        key: "",
        name: "index",
        path: "/",
        component: () => import("@/App.vue"),
        redirect: "/dashboard",
        meta: {
            title: "首页"
        },
        children: [...routers]
    },
    {
        path: "/:pathMatch(.*)",
        redirect: "/404"
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 };
    }
});

router.beforeEach(async (to, from, next) => {
    start();
    to.meta.tabName && (document.title = to.meta.tabName);
    // document.body.style.cssText = "background-color:#f6f6f2 !important";
    //判断是否需要权限验证，默认都是需要的
    if (!to.meta.noAuthCheck) {
        try {
            await managerAuthCheck(to.path);
        } catch (error) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.code == 403) {
                next("/403");
                return;
            } else {
                next("/503");
                return;
            }
        }
    }
    if (to.meta.needLogin && !getToken()) {
        next(getLoginUrl());
        return;
    }
    let tabs = !store.state.session.tabs ? [] : store.state.session.tabs;
    if (!tabs || !tabs.length) {
        tabs.push({ name: 'Workplace', title: '工作台', key: '/dashboard/workplace', refresh: 0, fixed: true })
        store.commit("session/setTabs", tabs);
    }
    if (!tabs.some(x => x.key == to.fullPath) && to.meta.needLogin) {
        tabs.push({ name: to.name, title: to.meta.title, key: to.fullPath, refresh: 0 });
        store.commit("session/setTabs", tabs);
    }
    setTabActive(to.fullPath)
    next();
});

router.afterEach(to => {
    close();
});

router.onError(() => {
    console.log("route错误");
});

export default router;
