export default [
    {
        path: "/product/category",
        name: "ProductCategory",
        component: () => import("@/layouts/Layout.vue"),
        redirect: "/product/category/list",
        meta: {
            title: "商品分类",
            needLogin: true, //需要登录
            keepAlive: true,
            bgColor: "#ffffff"
        },
        children: [
            {
                path: "/product/category/list",
                name: "ProductCategoryList",
                component: () => import("@/views/product/CategoryList.vue"),
                meta: {
                    title: "商品分类"
                }
            }
        ]
    },
    {
        path: "/product/type",
        name: "ProductType",
        component: () => import("@/layouts/Layout.vue"),
        redirect: "/product/type/list",
        meta: {
            title: "商品类型",
            needLogin: true, //需要登录
            keepAlive: true,
            bgColor: "#ffffff"
        },
        children: [
            {
                path: "/product/type/list",
                name: "ProductTypeList",
                component: () => import("@/views/product/TypeList.vue"),
                meta: {
                    title: "商品类型"
                }
            },
            {
                path: "/product/type/attribute/list",
                name: "ProductTypeAttributeList",
                component: () => import("@/views/product/TypeAttributeList.vue"),
                meta: {
                    title: "商品类型属性"
                }
            },
            {
                path: "/product/type/attribute/detail",
                name: "ProductTypeAttributeDetail",
                component: () => import("@/views/product/TypeAttributeDetail.vue"),
                meta: {
                    title: "商品类型属性详情",
                    parent: "ProductTypeAttributeList"
                }
            }
        ]
    },
    {
        path: "/product/info",
        name: "ProductInfo",
        component: () => import("@/layouts/Layout.vue"),
        redirect: "/product/info/list",
        meta: {
            title: "商品列表",
            needLogin: true, //需要登录
            keepAlive: true,
            bgColor: "#ffffff"
        },
        children: [
            {
                path: "/product/info/list",
                name: "ProductInfoList",
                component: () => import("@/views/product/InfoList.vue"),
                meta: {
                    title: "商品列表"
                }
            },
            {
                path: "/product/info/detail",
                name: "ProductInfoEdit",
                component: () => import("@/views/product/InfoDetail.vue"),
                meta: {
                    title: "商品详情"
                }
            }
        ]
    },
];
