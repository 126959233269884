import storage from "store";

export const getLoginUrl = function () {
    storage.remove("ACCESS_TOKEN");
    const href = encodeURIComponent(location.href);
    return `/manager/login?redirect_uri=${href}`;
};

export const setToken = function (token) {
    storage.set("ACCESS_TOKEN", token);
};

export const getToken = function () {
    return storage.get("ACCESS_TOKEN");
};
