export default [
    {
        path: "/system/menu",
        name: "SystemMenu",
        component: () => import("@/layouts/Layout.vue"),
        redirect: "/system/menu/list",
        meta: {
            title: "菜单管理",
            needLogin: true, //需要登录
            keepAlive: true,
            bgColor: "#ffffff"
        },
        children: [
            {
                path: "/system/menu/list",
                name: "SystemMenuList",
                component: () => import("@/views/sytem/MenuList.vue"),
                meta: {
                    title: "菜单管理"
                }
            }
        ]
    },
    {
        path: "/system/manager",
        name: "SystemManager",
        component: () => import("@/layouts/Layout.vue"),
        redirect: "/system/manager/list",
        meta: {
            title: "用户管理",
            needLogin: true, //需要登录
            keepAlive: true,
            bgColor: "#ffffff"
        },
        children: [
            {
                path: "/system/manager/list",
                name: "SystemManagerList",
                component: () => import("@/views/sytem/ManagerList.vue"),
                meta: {
                    title: "用户管理"
                }
            }
        ]
    },
    {
        path: "/system/resource",
        name: "SystemResource",
        component: () => import("@/layouts/Layout.vue"),
        redirect: "/system/resource/list",
        meta: {
            title: "系统资源",
            needLogin: true, //需要登录
            keepAlive: true,
            bgColor: "#ffffff"
        },
        children: [
            {
                path: "/system/resource/list",
                name: "SystemResourceList",
                component: () => import("@/views/sytem/ResourceList.vue"),
                meta: {
                    title: "系统资源"
                }
            }
        ]
    },
    {
        path: "/system/role",
        name: "SystemRole",
        component: () => import("@/layouts/Layout.vue"),
        redirect: "/system/role/list",
        meta: {
            title: "角色管理",
            needLogin: true, //需要登录
            keepAlive: true,
            bgColor: "#ffffff"
        },
        children: [
            {
                path: "/system/role/list",
                name: "SystemRoleList",
                component: () => import("@/views/sytem/RoleList.vue"),
                meta: {
                    title: "角色管理"
                }
            }
        ]
    },
    {
        path: "/system/log",
        name: "SystemLog",
        component: () => import("@/layouts/Layout.vue"),
        redirect: "/system/log/list",
        meta: {
            title: "系统日志",
            needLogin: true, //需要登录
            keepAlive: true,
            bgColor: "#ffffff"
        },
        children: [
            {
                path: "/system/log/list",
                name: "SystemLogList",
                component: () => import("@/views/sytem/LogList.vue"),
                meta: {
                    title: "系统日志"
                }
            }
        ]
    },
    {
        path: "/system/param",
        name: "SystemParam",
        component: () => import("@/layouts/Layout.vue"),
        redirect: "/system/param/list",
        meta: {
            title: "系统参数",
            needLogin: true, //需要登录
            keepAlive: true,
            bgColor: "#ffffff"
        },
        children: [
            {
                path: "/system/param/list",
                name: "SystemParamList",
                component: () => import("@/views/sytem/ParamList.vue"),
                meta: {
                    title: "系统参数"
                }
            }
        ]
    },
];
