import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

const session = {
    namespaced: true,
    state: {
        tabs: []
    },
    mutations: {
        setTabs(state, payload) {
            state.tabs = payload;
        },
    },
}
const local = {
    namespaced: true,
    state: {
        dict: []
    },
    mutations: {
        setDict(state, payload) {
            state.dict = payload;
        },
    },
}


export default createStore({
    modules: {
        session,
        local
    },
    plugins: [createPersistedState({
        key: "SESSION_STORE_DATA",
        paths: ['session'],
        storage: window.sessionStorage,
    }), createPersistedState({
        key: "LOCAL_STORE_DATA",
        paths: ['local'],
        storage: window.localStorage,
    })]
});