export default [
    {
        path: "/user",
        name: "User",
        component: () => import("@/layouts/Layout.vue"),
        redirect: "/user/list",
        meta: {
            title: "用户列表",
            needLogin: true, //需要登录
            keepAlive: true,
            bgColor: "#ffffff"
        },
        children: [
            {
                path: "list",
                name: "UserList",
                component: () => import("@/views/user/List.vue"),
                meta: {
                    title: "用户列表"
                }
            },
            {
                path: "detail",
                name: "UserDetail",
                component: () => import("@/views/user/Detail.vue"),
                meta: {
                    title: "用户详情"
                }
            }
        ]
    }
];
