import { createApp } from "vue";
import App from "./App.vue";
import store from "./store"
import router from "./router";
import "./style.less";
// import "ant-design-vue/es/message/style/css";
// import "ant-design-vue/es/notification/style/css";
// import "ant-design-vue/es/modal/style/css";

import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";

// 定义特性标志
window.__VUE_PROD_DEVTOOLS__ = false;
window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false;

const app = createApp(App);
app.use(store);
app.use(router);
app.use(PerfectScrollbar);
app.mount("#app");
