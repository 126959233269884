export default [
    {
        path: "/home/banner",
        name: "HomeBanner",
        component: () => import("@/layouts/Layout.vue"),
        redirect: "/home/banner/list",
        meta: {
            title: "首页轮播图",
            needLogin: true, //需要登录
            keepAlive: true,
        },
        children: [
            {
                path: "list",
                name: "HomeBannerList",
                component: () => import("@/views/home/BannerList.vue"),
                meta: {
                    title: "首页轮播图"
                }
            }
        ]
    },
    {
        path: "/home/menu",
        name: "HomeMenu",
        component: () => import("@/layouts/Layout.vue"),
        redirect: "/home/menu/list",
        meta: {
            title: "首页菜单",
            needLogin: true, //需要登录
            keepAlive: true,
        },
        children: [
            {
                path: "list",
                name: "HomeMenuList",
                component: () => import("@/views/home/MenuList.vue"),
                meta: {
                    title: "首页菜单"
                }
            }
        ]
    }
];
