export default [
    {
        path: "/404",
        name: "NotFound",
        component: () => import("@/layouts/Layout.vue"),
        redirect: "/404",
        meta: {
            title: "404",
            needLogin: true, //需要登录
            keepAlive: false,
            noAuthCheck: true, //不需要权限验证
            hideBreadcrumb: true //隐藏面包屑
        },
        children: [
            {
                path: "/403",
                name: "NotAuth",
                component: () => import(/* webpackChunkName: "error" */ "@/views/exception/403.vue"),
                meta: {
                    title: "403"
                }
            },
            {
                path: "/404",
                name: "NotFound",
                component: () => import(/* webpackChunkName: "error" */ "@/views/exception/404.vue"),
                meta: {
                    title: "404"
                }
            },
            {
                path: "/503",
                name: "SystemError",
                component: () => import(/* webpackChunkName: "error" */ "@/views/exception/503.vue"),
                meta: {
                    title: "503"
                }
            }
        ]
    }
];
