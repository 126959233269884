export default [
    {
        path: "/message/sms",
        name: "MessageSms",
        component: () => import("@/layouts/Layout.vue"),
        redirect: "/message/sms/list",
        meta: {
            title: "短信消息",
            needLogin: true, //需要登录
            keepAlive: true,
            bgColor: "#ffffff"
        },
        children: [
            {
                path: "/message/sms/list",
                name: "MessageSmsList",
                component: () => import("@/views/message/SmsList.vue"),
                meta: {
                    title: "短信消息"
                }
            }
        ]
    },
];
