import router from "@/router";
import store from '@/store';

export const tabActive = ref('');

//更新tabActive
export const setTabActive = (newTabActive) => {
    tabActive.value = newTabActive;
};

//设置tab的标题
export const setTabTitle = (tabTitle) => {
    const fullPath = router.currentRoute.value.fullPath;
    let tabs = store.state.session.tabs || [];
    let tabIndex = tabs.findIndex(x => x.key == fullPath)
    if (tabIndex < 0) {
        return;
    }
    tabs[tabIndex].title = tabTitle
    store.commit("session/setTabs", tabs);
}

