export default [
    {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("@/layouts/Layout.vue"),
        redirect: "/dashboard/workplace",
        meta: {
            title: "仪表盘",
            needLogin: true, //需要登录
            keepAlive: true,
            noAuthCheck: true //不需要权限验证
        },
        children: [
            {
                path: "workplace",
                name: "Workplace",
                component: () => import("@/views/dashboard/Workplace.vue"),
                meta: {
                    title: "工作台"
                }
            }
        ]
    }
];
