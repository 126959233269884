export default [
    {
        path: "/manager/login",
        name: "ManagerLogin",
        component: () => import("@/views/manager/Login.vue"),
        meta: {
            title: "商城管理后台登录",
            keepAlive: true,
            noAuthCheck: true //不需要权限验证
        }
    },
    {
        path: "/manager",
        name: "Manager",
        component: () => import("@/layouts/Layout.vue"),
        redirect: "/manager/info",
        meta: {
            title: "个人中心",
            needLogin: true, //需要登录
            keepAlive: true,
            noAuthCheck: true //不需要权限验证
        },
        children: [
            {
                path: "/manager/center",
                name: "ManagerCenter",
                component: () => import("@/layouts/Manager.vue"),
                redirect: "/manager/info",
                meta: {
                    title: "个人中心",
                    keepAlive: true,
                    hideBreadcrumb: true
                },
                children: [
                    {
                        path: "/manager/info",
                        name: "ManagerInfo",
                        component: () => import("@/views/manager/Info.vue"),
                        meta: {
                            title: "基本信息",
                            keepAlive: true,
                            hideBreadcrumb: false
                        }
                    },
                    {
                        path: "/manager/setting",
                        name: "ManagerSetting",
                        component: () => import("@/views/manager/Setting.vue"),
                        meta: {
                            title: "密码修改",
                            keepAlive: true,
                            hideBreadcrumb: false
                        }
                    }
                ]
            }
        ]
    }
];
