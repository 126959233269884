import request from "../utils/axios";

//管理员登录
export const managerLogin = data => {
    return request({
        url: `/manage/user/login`,
        method: "POST",
        data: data
    });
};
//管理员授权菜单
export const managerAuthorizedMenus = () => {
    return request({
        url: `/manage/user/authorized/menus`,
        method: "GET",
        params: null
    });
};
//管理员权限验证
export const managerAuthCheck = (url) => {
    return request({
        url: `/manage/user/authorized/check`,
        method: "GET",
        params: { url: url }
    });
};
//管理员信息
export const managerInfo = () => {
    return request({
        url: `/manage/user/info`,
        method: "GET",
        data: {}
    });
};

//管理员信息更新
export const managerUpdateInfo = data => {
    return request({
        url: `/manage/user/update/info`,
        method: "POST",
        data: data
    });
};
//管理员密码更新
export const managerUpdatePassword = data => {
    return request({
        url: `/manage/user/update/password`,
        method: "POST",
        data: data
    });
};

//管理员分页
export const managerPage = data => {
    return request({
        url: `/manage/user/page`,
        method: "GET",
        params: data
    });
};
//管理员添加
export const managerAdd = data => {
    return request({
        url: `/manage/user/add`,
        method: "POST",
        data: data
    });
};
//管理员详情
export const managerDetail = id => {
    return request({
        url: `/manage/user/${id}/detail`,
        method: "GET",
        data: null
    });
};
//管理员重置密码
export const managerResetPassword = id => {
    return request({
        url: `/manage/user/${id}/reset/password`,
        method: "PATCH",
        data: null
    });
};
//更新管理员信息是否可用
export const managerEnable = (id, enable) => {
    return request({
        url: `/manage/user/${id}/enable/${enable}`,
        method: "PATCH",
        data: null
    });
};
//管理员已分配的角色
export const managerAssignedRoles = id => {
    return request({
        url: `/manage/user/${id}/assigned/roles`,
        method: "GET",
        params: null
    });
};
//给管理员分配角色
export const managerAssignRole = (id, data) => {
    return request({
        url: `/manage/user/${id}/assign/role`,
        method: "PUT",
        data: data
    });
};

//所有角色
export const manageRoleAll = () => {
    return request({
        url: `/manage/role/all`,
        method: "GET",
        params: null
    });
};
//角色添加
export const manageRoleAdd = data => {
    return request({
        url: `/manage/role/add`,
        method: "POST",
        data: data
    });
};
//角色分页
export const manageRolePage = params => {
    return request({
        url: `/manage/role/page`,
        method: "GET",
        params: params
    });
};
//角色信息
export const manageRoleInfo = id => {
    return request({
        url: `/manage/role/${id}/info`,
        method: "GET",
        data: null
    });
};
//更新角色是否可用
export const manageRoleEnable = (id, enable) => {
    return request({
        url: `/manage/role/${id}/enable/${enable}`,
        method: "PATCH",
        data: null
    });
};
//删除角色
export const manageRoleDel = id => {
    return request({
        url: `/manage/role/${id}/del`,
        method: "DELETE",
        data: null
    });
};
//角色已分配的菜单
export const manageRoleAssignedMenus = id => {
    return request({
        url: `/manage/role/${id}/assigned/menus`,
        method: "GET",
        params: null
    });
};
//给角色分配菜单
export const manageRoleAssignMenu = (id, data) => {
    return request({
        url: `/manage/role/${id}/assign/menu`,
        method: "PUT",
        data: data
    });
};
//角色已分配的资源
export const manageRoleAssignedResources = id => {
    return request({
        url: `/manage/role/${id}/assigned/resources`,
        method: "GET",
        params: null
    });
};
//给角色分配资源
export const manageRoleAssignResource = (id, data) => {
    return request({
        url: `/manage/role/${id}/assign/resource`,
        method: "PUT",
        data: data
    });
};
