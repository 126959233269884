import axios from "axios";
import get from "lodash/get";
import router from "@/router";
import { getLoginUrl, getToken } from "@/utils/login";
import { message } from "ant-design-vue";
import { notification } from "ant-design-vue";
// import { start, close } from "@/utils/nprogress";
let CancelToken = axios.CancelToken;
let source = CancelToken.source();

// 创建 axios 实例
const request = axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    timeout: 10000 // 请求超时时间
});
// 异常拦截处理器
const errorHandler = error => {
    if (error.response) {
        const status = get(error, "response.status");
        switch (status) {
            /* eslint-disable no-param-reassign */
            case 400:
                error.message = "请求错误";
                break;
            case 401:
                error.message = "未授权，请登录";
                break;
            case 403:
                let message = "权限不足";
                if (error.response.data && error.response.data.message) {
                    message = error.response.data.message;
                }
                error.message = message;
                break;
            case 404:
                error.message = `请求地址出错: ${error.response.config.url}`;
                break;
            case 408:
                error.message = "请求超时";
                break;
            case 500:
                error.message = "服务器内部错误";
                break;
            case 501:
                error.message = "服务未实现";
                break;
            case 502:
                error.message = "网关错误";
                break;
            case 503:
                error.message = "服务不可用";
                return router.push("/503");
            case 504:
                error.message = "网关超时";
                break;
            case 505:
                error.message = "HTTP版本不受支持";
                break;
            default:
                break;
        }
    } else if (error.request) {
        // 请求已发出，但没有收到响应
        error.message = '网络请求失败，请检查网络连接';
    } else {
        // 在设置请求时发生了一些问题
        error.message = '请求发送失败，请检查网络连接';
    }
    if (axios.isCancel(error)) {
        console.log("请求已取消")
        source = CancelToken.source();
        return new Promise(() => { });
    } else {
        notification.error({
            message: "系统错误",
            description: error.message
        });
        return Promise.reject(error);
    }
};
request.interceptors.request.use(config => {
    // start();
    config.headers.Authorization = `${getToken()}`;
    // config.headers.UserTempId = window.SHANGYUN.pvID;
    config.cancelToken = source.token;
    return config;
}, errorHandler);
request.interceptors.response.use(response => {
    // close();
    const dataAxios = response.data;
    if (!dataAxios.code) {
        return Promise.reject("接口错误");
    }
    switch (dataAxios.code) {
        case 200:
            return dataAxios.data;
        case 401:
            // 登录失效
            window.location.href = getLoginUrl();
            return Promise.reject(new Error(dataAxios));
        default:
            message.error(dataAxios.message);
            console.error(dataAxios);
            return Promise.reject(new Error(dataAxios));
    }
}, errorHandler);
export default request;
